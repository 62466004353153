<template>
  <header class="header">
    <div class="header-top">
      <div class="logo">
        <img src="logo.png" alt="ねこなら">
        <span>ねこなら掲示板</span>
      </div>
      <div class="header-icons">
        <img src="chat-icon.png" alt="チャット" class="icon">
        <img src="notification-icon.png" alt="通知" class="icon">
        <img src="profile-icon.png" alt="プロフィール" class="icon">
        <button class="download-button">アプリをダウンロード</button>
      </div>
    </div>
    <nav class="nav">
      <router-link to="/" class="nav-link">ホーム</router-link>
      <router-link to="/lost" class="nav-link">迷子情報</router-link>
      <router-link to="/found" class="nav-link">保護情報</router-link>
     <router-link to="/found" class="nav-link">お問い合わせ</router-link>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'AppHeader'
}
</script>

<style scoped>
.header {
  width: 100%;
  background-color: #F25757;
  box-sizing: border-box;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 40px;
  margin-right: 10px;
}

.nav {
  display: flex;
  justify-content: center;
  gap: 40px; /* スペースを増やすために gap を増やす */
  padding: 15px 0; /* パディングを増やしてナビゲーションをおしゃれに */
  background-color: white;
  margin: 0;
}

.nav-link {
  color: #F25757;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px; /* フォントサイズを少し大きくする */
  transition: color 0.3s;
}

.nav-link:hover {
  color: #E24646; /* ホバー時のカラーを設定 */
}

.header-icons {
  display: flex;
  align-items: center;
  gap: 15px;
}

.icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid white;
}

.download-button {
  padding: 10px 20px;
  background-color: #E24646;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.download-button:hover {
  background-color: #D33D3D; /* ホバー時の背景色を設定 */
}
</style>
