import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Post from '../views/Post.vue';
import CatDetails from '../views/CatDetails.vue';
import ProtectInformationPostPage from '../views/ProtectInformationPostPage.vue'; // 変更点

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: Home
  },
  {
    path: '/post',
    name: 'PostPage',
    component: Post
  },
  {
    path: '/cat/:id',
    name: 'CatDetailsPage',
    component: CatDetails
  },
  {
    path: '/protect',
    name: 'ProtectInformationPostPage', // 変更点
    component: ProtectInformationPostPage // 変更点
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
