<template>
  <div>
    <h1>保護情報を投稿</h1>
    <form @submit.prevent="submitForm">
      <input type="text" v-model="name" placeholder="猫の名前">
      <input type="text" v-model="location" placeholder="保護した場所">
      <input type="file" @change="onFileChange">
      <textarea v-model="description" placeholder="詳細情報"></textarea>
      <button type="submit">投稿</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ProtectInformationPostPage', // 複数の単語に変更
  data() {
    return {
      name: '',
      location: '',
      description: '',
      file: null
    };
  },
  methods: {
    onFileChange(e) {
      this.file = e.target.files[0];
    },
    async submitForm() {
      const formData = new FormData();
      formData.append('name', this.name);
      formData.append('location', this.location);
      formData.append('description', this.description);
      formData.append('file', this.file);

      try {
        await axios.post('http://localhost:3000/api/protects', formData);
        this.$router.push('/');
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>
