<template>
  <div id="app">
    <AppHeader />
    <router-view />
    <footer class="footer">
      <div class="footer-links">
        <a href="https://corp.woldy.co.jp" class="footer-link">運営会社</a>
        <a href="/terms" class="footer-link">利用規約</a>
        <a href="/privacy" class="footer-link">プライバシーポリシー</a>
        <a href="https://nekonara.net" class="footer-link">サービスサイト</a>
      </div>
    </footer>
  </div>
</template>

<script>
import AppHeader from './components/Header.vue'

export default {
  name: 'App',
  components: {
    AppHeader
  }
}
</script>

<style>
#app {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif; /* 柔らかい感じのフォント */
}

.header {
  width: 100%;
  background-color: #F25757;
  box-sizing: border-box;
  padding: 0; /* パディングを0にして四隅のスペースをなくす */
  margin: 0; /* マージンを0にして四隅のスペースをなくす */
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 40px;
  margin-right: 10px;
}

.nav {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 10px 0;
  background-color: white; /* 2行目の背景色を白に設定 */
  margin: 0; /* 余計なマージンをリセット */
  width: 100%; /* 幅を100%に設定 */
  box-sizing: border-box; /* ボックスサイズを境界ボックスに設定 */
}

.nav-link {
  color: #F25757; /* 文字色をピンクに設定 */
  text-decoration: none;
  font-weight: bold;
}

.header-icons {
  display: flex;
  align-items: center;
  gap: 15px;
}

.icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid white;
}

.download-button {
  padding: 10px 20px;
  background-color: #E24646;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

.footer {
  text-align: center;
  padding: 10px 0;
  margin-top: 20px;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.footer-link {
  color: gray; /* 文字色を灰色に設定 */
  text-decoration: none;
  font-weight: bold;
  font-size: 12px; /* 文字サイズを小さく設定 */
}

.footer-link:hover {
  text-decoration: underline;
}
</style>
