<template>
  <div class="page-container">
    <p class="description">ねこならの掲示板では猫ちゃんの迷子情報・保護情報を掲載し無料で他のユーザーとメッセージでやり取りができます。</p>
    <p class="instruction">猫ちゃんの迷子情報・保護情報はこちらから投稿できます。</p>
    <div class="button-container">
      <router-link to="/post" class="button lost-button">迷子情報を投稿</router-link>
      <router-link to="/protect" class="button found-button">保護情報を投稿</router-link>
    </div>
    <div class="search-form">
      <input type="date" v-model="searchDate" placeholder="日付">
      <select v-model="searchPrefecture">
          <option value="">都道府県を選択</option>
          <option value="北海道">北海道</option>
          <option value="青森県">青森県</option>
          <option value="岩手県">岩手県</option>
          <option value="宮城県">宮城県</option>
          <option value="秋田県">秋田県</option>
          <option value="山形県">山形県</option>
          <option value="福島県">福島県</option>
          <option value="茨城県">茨城県</option>
          <option value="栃木県">栃木県</option>
          <option value="群馬県">群馬県</option>
          <option value="埼玉県">埼玉県</option>
          <option value="千葉県">千葉県</option>
          <option value="東京都">東京都</option>
          <option value="神奈川県">神奈川県</option>
          <option value="新潟県">新潟県</option>
          <option value="富山県">富山県</option>
          <option value="石川県">石川県</option>
          <option value="福井県">福井県</option>
          <option value="山梨県">山梨県</option>
          <option value="長野県">長野県</option>
          <option value="岐阜県">岐阜県</option>
          <option value="静岡県">静岡県</option>
          <option value="愛知県">愛知県</option>
          <option value="三重県">三重県</option>
          <option value="滋賀県">滋賀県</option>
          <option value="京都府">京都府</option>
          <option value="大阪府">大阪府</option>
          <option value="兵庫県">兵庫県</option>
          <option value="奈良県">奈良県</option>
          <option value="和歌山県">和歌山県</option>
          <option value="鳥取県">鳥取県</option>
          <option value="島根県">島根県</option>
          <option value="岡山県">岡山県</option>
          <option value="広島県">広島県</option>
          <option value="山口県">山口県</option>
          <option value="徳島県">徳島県</option>
          <option value="香川県">香川県</option>
          <option value="愛媛県">愛媛県</option>
          <option value="高知県">高知県</option>
          <option value="福岡県">福岡県</option>
          <option value="佐賀県">佐賀県</option>
          <option value="長崎県">長崎県</option>
          <option value="熊本県">熊本県</option>
          <option value="大分県">大分県</option>
          <option value="宮崎県">宮崎県</option>
          <option value="鹿児島県">鹿児島県</option>
          <option value="沖縄県">沖縄県</option>
        </select>
      <input type="text" v-model="searchCity" placeholder="市区町村">
      <input type="text" v-model="searchKeyword" placeholder="キーワード">
      <button @click="search">検索</button>
    </div>
    <h2 class="section-title">新着情報</h2>
    <div class="cat-photos">
      <div v-for="(label, index) in labels" :key="index" class="cat-photo">
        <div :class="['photo-label', getLabelClass(label)]">{{ label }}</div>
        <img src="https://via.placeholder.com/200" alt="Cat photo">
        <div class="cat-details">
          <div>場所: 静岡県浜松市南区芳川町 周辺</div>
          <div>体毛の色: 茶トラ、お腹は白っぽい</div>
          <div>瞳の色: 金</div>
          <div>特徴: 茶トラの男の子、去勢済みです</div>
          <button class="details-button" @click="viewDetails(index)">詳細</button>
        </div>
      </div>
    </div>
    <div class="page-footer">
      <div class="photo-copyright">© Woldy</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  data() {
    return {
      labels: [],
      searchDate: '',
      searchPrefecture: '',
      searchCity: '',
      searchKeyword: ''
    };
  },
  created() {
    this.generateLabels();
  },
 methods: {
    generateLabels() {
      const labels = ['迷子', '保護'];
      this.labels = Array.from({ length: 8 }, () => {
        const label = labels[Math.floor(Math.random() * labels.length)];
        return label;
      });
    },
    getLabelClass(label) {
      return label === '迷子' ? 'lost' : 'found';
    },
    search() {
      // 検索ロジックを実装
      console.log('検索条件:', this.searchDate, this.searchPrefecture, this.searchCity, this.searchKeyword);
    }
  }
};
</script>

.details-button {
  padding: 5px 10px;
  background-color: #808080;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  margin-top: 10px;
}

.details-button:hover {
  background-color: #696969;
}

<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F25757;
  padding: 10px 20px;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 40px;
  margin-right: 10px;
}

.nav {
  display: flex;
  gap: 20px;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.header-icons {
  display: flex;
  align-items: center;
  gap: 15px;
}

.icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid white;
}

.download-button {
  padding: 10px 20px;
  background-color: #E24646;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

.description, .instruction {
  text-align: center;
  margin: 10px 0;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
}

.lost-button {
  background-color: #F25757;
}

.found-button {
  background-color: #4AA578;
}

.search-button {
  background-color: #FFD700;
}

.lost-button:hover {
  background-color: #E24646;
}

.found-button:hover {
  background-color: #3A9A6E;
}

.search-button:hover {
  background-color: #FFC107;
}

.section-title {
  text-align: center;
  margin-top: 40px;
  font-size: 24px;
  color: #333;
}

.cat-photos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  padding: 0 10px;
}

.cat-photo {
  text-align: center;
  position: relative;
  width: 200px;
}

.cat-photo img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

.cat-details {
  text-align: left;
  padding: 10px;
  font-size: 14px;
  color: #333;
}

.photo-label {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  color: white;
  max-width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.photo-label.lost {
  background-color: #F25757;
}

.photo-label.found {
  background-color: #4AA578;
}

.page-footer {
  margin-top: auto;
  text-align: center;
  padding: 10px 0;
}

.photo-copyright {
  font-size: 12px;
  color: #666;
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .cat-photos {
    flex-direction: column;
    align-items: center;
  }

  .cat-photo {
    width: 100%;
    max-width: 200px;
  }
}

/* 検索フォームのスタイル */
.search-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
}

.search-form input,
.search-form select,
.search-form button {
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.search-button {
  background-color: #808080; /* グレーの色 */
  color: #fff;
}

.search-button:hover {
  background-color: #696969; /* 濃いグレーの色 */
}
</style>
