<template>
  <div>
    <h1>迷子情報を投稿する前にご確認ください。</h1>
    <div class="confirmation">
      <p>警察に届出をしましたか？</p>
      <p>保健所・動物愛護センターに問い合わせましたか？</p>
      <p class="alert">猫ちゃんの迷子情報のみ投稿してください。</p>
      <ul>
        <li>電話番号やメールアドレスは入力せず、他のユーザーとの連絡はねこならのメッセージ機能を利用ください。</li>
        <li>個人情報保護のため個人が特定される情報は入力しないでください。</li>
        <li>報酬として金品を提示／要求しないでください。</li>
        <li><a href="#">利用規約</a>に同意の上、投稿してください。</li>
      </ul>
    </div>

    <h1>迷子情報を投稿</h1>
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <label for="prefecture">迷子になってしまった場所</label>
        <select id="prefecture" v-model="prefecture" required>
  <option value="">都道府県を選択してください</option>
  <option value="北海道">北海道</option>
  <option value="青森県">青森県</option>
  <option value="岩手県">岩手県</option>
  <option value="宮城県">宮城県</option>
  <option value="秋田県">秋田県</option>
  <option value="山形県">山形県</option>
  <option value="福島県">福島県</option>
  <option value="茨城県">茨城県</option>
  <option value="栃木県">栃木県</option>
  <option value="群馬県">群馬県</option>
  <option value="埼玉県">埼玉県</option>
  <option value="千葉県">千葉県</option>
  <option value="東京都">東京都</option>
  <option value="神奈川県">神奈川県</option>
  <option value="新潟県">新潟県</option>
  <option value="富山県">富山県</option>
  <option value="石川県">石川県</option>
  <option value="福井県">福井県</option>
  <option value="山梨県">山梨県</option>
  <option value="長野県">長野県</option>
  <option value="岐阜県">岐阜県</option>
  <option value="静岡県">静岡県</option>
  <option value="愛知県">愛知県</option>
  <option value="三重県">三重県</option>
  <option value="滋賀県">滋賀県</option>
  <option value="京都府">京都府</option>
  <option value="大阪府">大阪府</option>
  <option value="兵庫県">兵庫県</option>
  <option value="奈良県">奈良県</option>
  <option value="和歌山県">和歌山県</option>
  <option value="鳥取県">鳥取県</option>
  <option value="島根県">島根県</option>
  <option value="岡山県">岡山県</option>
  <option value="広島県">広島県</option>
  <option value="山口県">山口県</option>
  <option value="徳島県">徳島県</option>
  <option value="香川県">香川県</option>
  <option value="愛媛県">愛媛県</option>
  <option value="高知県">高知県</option>
  <option value="福岡県">福岡県</option>
  <option value="佐賀県">佐賀県</option>
  <option value="長崎県">長崎県</option>
  <option value="熊本県">熊本県</option>
  <option value="大分県">大分県</option>
  <option value="宮崎県">宮崎県</option>
  <option value="鹿児島県">鹿児島県</option>
  <option value="沖縄県">沖縄県</option>
</select>
        <input type="text" v-model="city" placeholder="市区町村" required>
        <input type="text" v-model="nearby" placeholder="付近（例：大阪城公園）">
      </div>

      <div class="form-group">
        <label for="date">迷子になってしまった日時</label>
        <input type="date" v-model="date" required>
        <input type="time" v-model="time" required>
      </div>

      <div class="form-group">
        <label for="situation">迷子になってしまった時の状況</label>
        <textarea v-model="situation" placeholder="状況を150文字以内で入力してください" maxlength="150"></textarea>
      </div>

      <div class="form-group">
        <label for="catName">迷子になってしまった猫ちゃんの情報</label>
        <input type="text" v-model="catName" placeholder="名前" required>
      <br>
      <br>
        <div>
          <label>性別</label>
          <input type="radio" id="male" value="男の子" v-model="gender" required>
          <label for="male">男の子</label>
          <input type="radio" id="female" value="女の子" v-model="gender" required>
          <label for="female">女の子</label>
        </div>
        <br>
        <div>
          <label>年齢</label>
          <input type="radio" id="kitten" value="子猫" v-model="age" required>
          <label for="kitten">子猫</label>
          <input type="radio" id="adult" value="成猫" v-model="age" required>
          <label for="adult">成猫</label>
          <input type="radio" id="senior" value="老猫" v-model="age" required>
          <label for="senior">老猫</label>
        </div>
        <br>
        <input type="text" v-model="pattern" placeholder="模様" required>
        <input type="text" v-model="fur" placeholder="毛色・柄" required>
        <br>
        <br>
        <div>
          <label>大きさ</label>
          <input type="radio" id="small" value="小型猫" v-model="size" required>
          <label for="small">小型猫</label>
          <input type="radio" id="medium" value="中型猫" v-model="size" required>
          <label for="medium">中型猫</label>
          <input type="radio" id="large" value="大型猫" v-model="size" required>
          <label for="large">大型猫</label>
        </div>
        <br>
        <div>
          <label>首輪の有無</label>
          <input type="radio" id="collar-yes" value="有" v-model="collar" required>
          <label for="collar-yes">有</label>
          <input type="radio" id="collar-no" value="無" v-model="collar" required>
          <label for="collar-no">無</label>
        </div>
        <input type="text" v-model="collarDescription" placeholder="例：赤色の首輪に鈴付き">
      </div>
    <div class="form-group">
  <label for="photos">猫ちゃんの写真をアップロード</label>
  <br>
  <input type="file" @change="onFileChange($event, 0)">
  <br>
  <input type="file" @change="onFileChange($event, 1)">
  <br>
  <input type="file" @change="onFileChange($event, 2)">
  <br>
  <input type="file" @change="onFileChange($event, 3)">
  <br>
  <input type="file" @change="onFileChange($event, 4)">
  <p class="photo-upload-instructions">最大5枚の写真をアップロードできます。追加した写真は以下にプレビューされます。</p>
  <div class="photo-previews">
    <div v-for="(image, index) in filteredPreviewImages" :key="index" class="photo-preview-container">
      <p>画像 {{ index + 1 }}</p>
      <img :src="image" class="photo-preview-large" alt="猫の写真のプレビュー">
    </div>
    </div>
  </div>
      <p>※必ず1枚は写真をアップロードしてください。</p>
      <p>※人の顔・家の外観などが写っていない写真を選んでください。</p>
      <p>※1枚目には体全体が写っている写真を選んでください。</p>
      <p>※出来るだけ画質の良い写真を選んでください。</p>
      <p>※色々な角度から撮った写真をアップロードしてください。</p>
      <p>※迷子になってしまった猫ちゃんの写真のみアップロードしてください。</p>
      <button type="submit">投稿内容を確認する</button>
  
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PostPage',
  data() {
    return {
      name: '',
      location: '',
      description: '',
      file: null,
      prefecture: '',
      city: '',
      nearby: '',
      date: '',
      time: '',
      fur: '',
      size: '',
      collar: '',
      collarDescription: '',
      other: '',
      situation: '',
      catName: '',
      gender: '',
      age: '',
      pattern: '',
      previewImages: Array(5).fill(null)
    };
  },
  methods: {
  onFileChange(event, index) {
    this.previewImages.splice(index, 1, URL.createObjectURL(event.target.files[0]));
  },
  async submitForm() {
    const formData = new FormData();
    formData.append('name', this.name);
    formData.append('location', this.location);
    formData.append('description', this.description);
    this.previewImages.forEach((image, index) => {
      if (image) {
        formData.append(`file${index}`, image);
      }
    });

    try {
      await axios.post('http://localhost:3000/api/posts', formData);
      this.$router.push('/');
    } catch (error) {
      console.error(error);
    }
  }
},
computed: {
  filteredPreviewImages() {
    return this.previewImages.filter(image => image !== null);
  }
}
};
</script>

<style scoped>
h1 {
  text-align: center;
}

.photo-upload-instructions {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

.photo-preview-large {
  width: 100%;
  max-width: 500px; /* プレビュー画像の最大幅を設定 */
  height: auto;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 20px; /* プレビュー画像の下にスペースを追加 */
}

.photo-previews {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.photo-preview {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.confirmation {
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 800px;
}

.confirmation p {
  font-weight: bold;
}

.confirmation .alert {
  color: red;
}

.confirmation ul {
  list-style-type: none;
  padding: 0;
}

.confirmation ul li {
  margin-bottom: 5px;
}

.form-group {
  margin-bottom: 20px;
}

form {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

input[type="text"],
input[type="date"],
input[type="time"],
textarea,
select {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

input[type="file"] {
  margin: 10px 0;
}

button {
  width: 100%;
  padding: 15px;
  background-color: #F25757;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

button:hover {
  background-color: #E24646;
}
</style>
