<template>
  <div>
    <h1>猫の詳細情報</h1>
    <p>{{ cat.name }}</p>
    <p>{{ cat.location }}</p>
    <p>{{ cat.description }}</p>
    <img :src="cat.image" alt="猫の画像">
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'CatDetailsPage',
  data() {
    return {
      cat: {}
    };
  },
  async created() {
    const id = this.$route.params.id;
    try {
      const response = await axios.get(`http://localhost:3000/api/cats/${id}`);
      this.cat = response.data;
    } catch (error) {
      console.error(error);
    }
  }
};
</script>
